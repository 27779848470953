import { ButtonVariantMap } from '../../../../dsl/atoms/Button/Button.config'

export const CART_COUPONS_COMPONENT_KEY = 'CartCouponsComponent'

export const CART_COUPONS_DEFAULT_CONFIG = {
  errorIcon: 'ErrorIcon',
  trashIcon: 'TrashIcon',
  // todo: mena: ButtonVariantMap.Outline
  clearBtnVariant: ButtonVariantMap.Full
}
