











































import { Component, Mixins } from 'vue-property-decorator'

import { logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { IToastMixin, ToastMixin } from '../../../shared'

import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'
import { CART_COUPONS_COMPONENT_KEY, CART_COUPONS_DEFAULT_CONFIG } from './CartCoupons.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CartCoupons>({
  name: 'CartCoupons',
  created () {
    this.config = this.getComponentConfig(CART_COUPONS_COMPONENT_KEY, CART_COUPONS_DEFAULT_CONFIG)
  }
})
export class CartCoupons extends Mixins<IToastMixin, IBaseCart,
  StructureConfigurable>(ToastMixin, BaseCartMixin, StructureConfigurable) {
  public applyingCoupon: boolean = false
  public couponCode: string = ''
  public errors: string[] = []

  /**
   * Determines
   */
  public get hasCoupons (): boolean {
    if (!this.cart) {
      return false
    }

    return Array.isArray(this.cart.getCoupons()) && this.cart.getCoupons().length > 0
  }

  public get hasErrors (): boolean {
    return this.errors.length > 0
  }

  public get trashIcon (): string {
    return this.getConfigProperty('trashIcon')
  }

  public get errorIcon (): string {
    return this.getConfigProperty('errorIcon')
  }

  public get clearBtnVariant (): string {
    return this.getConfigProperty('clearBtnVariant')
  }

  /**
   * Applies coupon code to cart.
   */
  public async applyCoupon (): Promise<void> {
    try {
      if (this.couponCode.length < 1) {
        return
      }

      this.applyingCoupon = true

      const updatedCart = await this.cartService.applyCoupon(this.cartId, this.couponCode)

      this.refreshCart(updatedCart)
    } catch (e) {
      logger(e, 'warn')
      this.errors = []
      this.errors.push((e as Error).message)
    } finally {
      this.applyingCoupon = false
    }
  }

  public async clearCoupons (): Promise<void> {
    try {
      const updatedCart = await this.cartService.clearCoupons(this.cartId)

      this.refreshCart(updatedCart)
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'danger')
    }
  }
}

export default CartCoupons
