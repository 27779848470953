







































































































import { Component, Mixins, Prop, Inject as VueInject } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { FullscreenLoader } from '../../../shared/molecules/Loader'
import { IProductsRepository, ProductsRepositoryType } from '../../../products/contracts/repositories'
import { IToastMixin, ToastMixin } from '../../../shared'
import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { SidesLayout } from '../../../shared/molecules/SidesLayout'

import { RouteName } from '../../routes'
import { SingleCartItem } from '../../organisms/SingleCartItem'
import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'

import { CART_SUMMARY_COMPONENT_KEY, CART_SUMMARY_DEFAULT_CONFIG } from './CartSummary.config'
import { CartBenefits } from '../CartBenefits/CartBenefits.vue'
import { CartCoupons } from '../CartCoupons/CartCoupons.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>  (edited)
 */
@Component<CartSummary>({
  name: 'CartSummary',
  components: {
    CartBenefits,
    CartCoupons,
    SidesLayout,
    SingleCartItem,
    FullscreenLoader
  },
  created (): void {
    this.config = this.getComponentConfig(CART_SUMMARY_COMPONENT_KEY, CART_SUMMARY_DEFAULT_CONFIG)
  }
})
export class CartSummary extends Mixins<IToastMixin, IBaseCart, CartAnalyticsMixin, StructureConfigurable>(
  ToastMixin,
  BaseCartMixin,
  CartAnalyticsMixin,
  StructureConfigurable
) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public readonly hasCartItems!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  public get calcCartCosts (): number {
    if (!this.cart) {
      return 0
    }

    if (!this.hasShipping) {
      return this.cart?.total.value
    }

    return this.cart?.total.value - this.shipping
  }

  /**
   * Determines whether shipping methods is selected.
   */
  public get hasShipping (): boolean {
    return !!this.cart && !!this.cart.selectedShippingMethod
  }

  /**
   * Determines whether there are discounts.
   */
  public get hasDiscounts (): boolean {
    if (!this.cart) {
      return false
    }

    return Array.isArray(this.cart.getDiscounts()) && this.cart.getDiscounts().length > 0
  }

  /**
   * Determines the price of the shipping.
   */
  public get shipping (): number {
    return this.cart?.getShippingPrice() ?? 0
  }

  public get freeDeliveryFrom (): boolean {
    return this.getConfigProperty('freeDeliveryFrom')
  }

  public get showBenefits (): boolean {
    return this.getConfigProperty('showBenefits')
  }

  public get showFreeDeliveryProgress (): boolean {
    return this.getConfigProperty('showFreeDeliveryProgress')
  }

  public get showTitle (): boolean {
    return this.getConfigProperty('showTitle')
  }

  public get changedOverall (): boolean {
    return this.getConfigProperty('changedOverall')
  }

  public get showShippingPreview (): boolean {
    return this.getConfigProperty('showShippingPreview')
  }

  public get couponsLocation (): string {
    return this.getConfigProperty('couponsLocation')
  }

  public get showTaxes (): boolean {
    return this.getConfigProperty('showTaxes')
  }

  public get showCoupons (): boolean {
    return this.getConfigProperty('showCoupons')
  }

  public get shouldDisplayTotalOnMobile (): boolean {
    return this.getConfigProperty('shouldDisplayTotalOnMobile')
  }

  public get subtotalWrappers (): string[] {
    return this.getConfigProperty('subtotalWrappers')
  }

  public get taxWrappers (): boolean {
    return this.getConfigProperty('taxWrappers')
  }

  public get toFreeDelivery (): { value: number; range: number } {
    if (!this.cart) {
      return { value: 0, range: 100 }
    }

    if (!this.hasShipping) {
      return {
        value: Number(Math.max(0, Number(this.freeDeliveryFrom) - this.cart.getTotalPrice())
          .toFixed(2)),
        range: ((this.cart.getTotalPrice() / Number(this.freeDeliveryFrom)) * 100)
      }
    }

    return {
      value: Number(Math.max(0, Number(this.freeDeliveryFrom) - this.totalWithoutShipping)
        .toFixed(2)),
      range: ((this.totalWithoutShipping / Number(this.freeDeliveryFrom)) * 100)
    }
  }

  public get totalWithoutShipping () {
    if (!this.cart) {
      return 0
    }
    if (!this.hasShipping) {
      return this.cart.getTotalPrice()
    }
    return this.cart.getTotalPrice() - this.shipping
  }

  public goToCheckout (): void {
    if (this.cart) {
      this.eventBus.emit('app:cart.view', this.getCartViewPayload(this.cart))
    }

    this.$router.push({ name: `checkout.${RouteName.Checkout}` })
  }
}

export default CartSummary
